import React from "react";
import ReactDOM from "react-dom";

import StoriesWidget from "../app/components/widgets/StoriesWidget";

import styles from "!!raw-loader!./widget_stories.css";
import stylesUtils from "!!raw-loader!../app/styles/abstracts/utils.css";
import leafletmcStyles from "!!raw-loader!leaflet.markercluster/dist/MarkerCluster.css"; // inside .js file

import { StyleSheetManager } from "styled-components";
function Widget({
  name,
  id,
  type,
  preview = false,
  ownerId,
  shopId = null,
  source = null,
  poweredby = false,
}) {
  return (
    <>
      <style>
        {stylesUtils}
        {styles}
        {leafletmcStyles}
      </style>
      <StoriesWidget
        widgetName={name}
        widgetId={id}
        widgetType={type}
        widgetPreview={preview}
        ownerId={ownerId}
        isWebComponent={true}
        shopId={shopId}
        source={source}
        poweredby={poweredby}
      />
      <div id="stories-modal"></div>
    </>
  );
}

export default class WebComponent extends HTMLElement {
  static get observedAttributes() {
    return [
      "name",
      "id",
      "type",
      "preview",
      "ownerid",
      "shopid",
      "source",
      "poweredby",
    ];
  }

  connectedCallback() {
    this.mountPoint = document.createElement("div");
    this.styleHost = document.createElement("div");
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot?.appendChild(this.styleHost);
    shadowRoot.appendChild(this.mountPoint);

    const name = this.getAttribute("name");
    const id = this.getAttribute("id");
    const type = this.getAttribute("type");
    const ownerId = this.getAttribute("ownerid");
    const shopId = this.getAttribute("shopid");
    const source = this.getAttribute("source");
    const poweredby = this.getAttribute("poweredby")=="true"

    const preview =
      typeof this.getAttribute("preview") == "string" ? true : false;
    ReactDOM.render(
      <StyleSheetManager target={this.styleHost}>
        <Widget
          name={name}
          id={id}
          type={type}
          preview={preview}
          ownerId={ownerId}
          shopId={shopId}
          source={source}
          poweredby={poweredby}
        />
      </StyleSheetManager>,
      this.mountPoint
    );
  }
}

window.customElements.define("rootip-component", WebComponent);
